<script lang="ts" setup>
import DialogReminderAddChange from '~/components/dialogs/DialogReminderAddChange.vue'
import ReminderCompact from '~/components/cards/ReminderCompact.vue'

const dialog = useDialog()
const props = defineProps<{
  date?: str,
  dateGt?: str,
  lead?: Lead
}>()

const contentType = computed<ContentType|undefined>(() => {
  if (props.lead) return { app_label: 'crm', model: 'lead' }
})

const objectId = computed<number|undefined>(() => {
  if (props.lead) return props.lead.id
})

const pagination = ref<PaginationInput>({
  page: 1,
  per_page: 5,
})

const fetchQuery = computed(()=> ({
  date: props.date,
  date_gt: props.dateGt,
  object_id: objectId.value,
  ...contentType.value,
  ...pagination.value
}))

const { data: objectList, pending: loading, refresh } = useApi<Paginated<Reminder>, ApiError>(`/reminders/`, {
  query: fetchQuery,
  watch: [pagination]
})
</script>

<template>
  <DataView
    :alwaysShowPaginator="false"
    :loading="loading"
    :rows="pagination.per_page"
    :totalRecords="objectList?.count"
    :value="objectList?.results"
    class="border-none overflow-y-auto"
    dataKey="id"
    lazy
    paginator
    style="height: 350px"
    @page="(event) => { pagination = getPagination(event) }"
  >
    <template #empty>
      <div class="py-10 text-center text-sm text-surface-400">
        Nothing to see here.
      </div>
    </template>
    <template #list="{ items }">
      <ul class="py-8 m-0 p-0 list-none">
        <ReminderCompact
          v-for="object in items"
          :key="object.id"
          :reminder="object"
          class="mb-5"
          @edit="dialog.open(DialogReminderAddChange, {
            props: {
              breakpoints:{
                '960px': '75vw',
                '640px': '90vw'
              },
              header: 'Edit reminder',
              modal: true,
              style: {
                width: '30vw'
              }
            },
            data: {
              contentType,
              reminder: object,
              lead: props.lead
            },
            onClose: () => refresh()
          })"
        />
      </ul>
    </template>
  </DataView>
</template>
